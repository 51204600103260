import { Close } from '@mui/icons-material';
import { Grid, IconButton, LinearProgress, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DeceasedTable from '../DeceasedTable';
import PhotoUploadForm from './photouploadform/PhotoUploadForm';
import UrnBox from './UrnBox';
import { useUrnsContext } from './UrnsContext';

const Urns = ({ editGraveValues }) => {
    const { graveId } = useParams();
    const { selectedUrn } = useUrnsContext();
    const [urns, setUrns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [urnContainer, setUrnContainer] = useState([0, 0]);
    const [searchValue, setSearchValue] = useState('');
    const [filteredUrns, setFilteredUrns] = useState([]);

    useEffect(() => {
        if (!editGraveValues?.urns || editGraveValues?.urns?.length === 0) {
            setUrnContainer([0, 0]);
            return;
        }
        setUrnContainer(
            editGraveValues.urns?.reduce(
                (prevValue, currValue) => [
                    Math.max(currValue.x, prevValue[0]),
                    Math.max(currValue.y, prevValue[1]),
                ],
                [0, 0]
            )
        );
    }, [editGraveValues?.urns, setUrnContainer]);

    useEffect(() => {
        const returnedUrns = [];

        if (!editGraveValues?.urns) {
            setLoading(false);
            setUrns([]);
            return;
        }

        for (let i = 0; i < urnContainer[0]; i += 1) {
            for (let j = 0; j < urnContainer[1]; j += 1) {
                const urn = editGraveValues.urns.find((e) => e.x === i + 1 && e.y === j + 1);
                returnedUrns.push({
                    ...(urn || {}),
                    X: i,
                    Y: j,
                });
            }
        }

        setLoading(false);
        setUrns(returnedUrns);
    }, [editGraveValues, urnContainer, setUrns]);

    useEffect(() => {
        if (searchValue === '') {
            setFilteredUrns([]);
            return;
        }

        setFilteredUrns(
            urns
                .filter(
                    (urn) =>
                        urn.deads
                            .flatMap((dead) => dead.name.toLowerCase())
                            .filter((k) => k.includes(searchValue.toLowerCase())).length > 0
                )
                .map((e) => e.id)
        );
    }, [searchValue, urns]);

    if (!graveId || editGraveValues?.urns?.length === 0) {
        return null;
    }

    if (loading) {
        return (
            <Grid
                item
                xs={12}
                sx={{
                    position: 'relative',
                    overflowX: 'auto',
                    height: `${urnContainer[1] * 35 + 16}px`,
                }}
            >
                <LinearProgress />
            </Grid>
        );
    }

    return (
        <>
            <Grid item container xs={12} sx={{ my: 2 }}>
                <Grid item xs={12} md={8} sx={{ my: 2 }}>
                    <Typography variant="h6">Urnafal</Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={{ my: 2 }}>
                    <TextField
                        label="Keresés név alapján"
                        value={searchValue}
                        onChange={(event) => setSearchValue(event.target.value)}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => setSearchValue('')}>
                                    <Close />
                                </IconButton>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <Grid
                item
                container
                xs={12}
                direction="column"
                sx={{
                    position: 'relative',
                    overflowX: 'auto',
                    height: `${urnContainer[1] * 35 + 16}px`,
                }}
            >
                {urns.map((urn) => (
                    <UrnBox key={`${urn.X}-${urn.Y}`} urn={urn} filteredUrns={filteredUrns} />
                ))}
            </Grid>
            <Grid item container xs={12} sx={{ pb: 2 }}>
                {selectedUrn && selectedUrn !== null && (
                    <>
                        <DeceasedTable deceased={selectedUrn?.deads || []} />
                        <PhotoUploadForm />
                    </>
                )}
            </Grid>
        </>
    );
};

export default Urns;
