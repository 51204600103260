import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';
import { MODAL_TYPE } from '../constants';

const ConfirmModal = ({ showModal, onClick, title }) => {
    const handleClose = () => {
        showModal(MODAL_TYPE.NONE);
    };

    return (
        <Dialog open onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogActions>
                <Button onClick={handleClose}>Mégse</Button>
                <Button
                    onClick={() => {
                        onClick();
                        handleClose();
                    }}
                >
                    Törlés
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmModal;
