import { AddRounded } from '@mui/icons-material';
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../providers/AppContext';
import SectionSelect from '../SectionSelect';

const GraveFilters = ({ initialGraveSearchParams, graveSearchParams, setGraveSearchParams }) => {
    const { selectedGraveyard } = useAppContext();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const navigate = useNavigate();

    const handleAddGrave = () => {
        navigate('/graves/new');
    };

    const handleResetFilters = () => {
        setGraveSearchParams({ ...initialGraveSearchParams });
        setStartDate(null);
        setEndDate(null);
    };

    useEffect(() => {
        if (!startDate) {
            setGraveSearchParams((prevValue) => ({
                ...prevValue,
                leaseStartDate: '',
            }));
            return;
        }
        let formattedDate = startDate;

        try {
            formattedDate = format(startDate, 'yyyy-MM-dd');
        } catch (error) {
            formattedDate = '';
        }

        setGraveSearchParams((prevValue) => ({
            ...prevValue,
            leaseStartDate: formattedDate,
        }));
    }, [startDate, setGraveSearchParams]);

    useEffect(() => {
        if (!endDate) {
            setGraveSearchParams((prevValue) => ({
                ...prevValue,
                leaseEndDate: '',
            }));
            return;
        }
        let formattedDate = endDate;

        try {
            formattedDate = format(endDate, 'yyyy-MM-dd');
        } catch (error) {
            formattedDate = '';
        }

        setGraveSearchParams((prevValue) => ({
            ...prevValue,
            leaseEndDate: formattedDate,
        }));
    }, [endDate, setGraveSearchParams]);

    return (
        <>
            <Grid
                container
                item
                xs={12}
                justifyContent="flex-end"
                alignItems="center"
                sx={{ mb: 2 }}
            >
                <Grid item xs={12} md={8}>
                    <Typography variant="h6" id="tableTitle" component="div">
                        Szűrők
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} textAlign="right">
                    <Button
                        variant="contained"
                        startIcon={<AddRounded />}
                        onClick={handleAddGrave}
                        disabled={!selectedGraveyard}
                    >
                        Új sírhely
                    </Button>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Parcella"
                        value={graveSearchParams.parcel}
                        onChange={(event) =>
                            setGraveSearchParams((prevValue) => ({
                                ...prevValue,
                                parcel: event.target.value,
                            }))
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Sor"
                        value={graveSearchParams.row}
                        onChange={(event) =>
                            setGraveSearchParams((prevValue) => ({
                                ...prevValue,
                                row: event.target.value,
                            }))
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Pozíció"
                        value={graveSearchParams.pos}
                        onChange={(event) =>
                            setGraveSearchParams((prevValue) => ({
                                ...prevValue,
                                pos: event.target.value,
                            }))
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body2">Sírhely bérlés lejárat</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <DatePicker
                            value={startDate}
                            inputFormat="yyyy-MM-dd"
                            onChange={(newDate) => setStartDate(newDate)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <DatePicker
                            value={endDate}
                            inputFormat="yyyy-MM-dd"
                            onChange={(newDate) => {
                                console.log(newDate);
                                setEndDate(newDate);
                            }}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SectionSelect
                        label="Szekció"
                        value={graveSearchParams.section}
                        setValue={(event) =>
                            setGraveSearchParams((prevValue) => ({
                                ...prevValue,
                                section: event.target.value,
                            }))
                        }
                    />
                </Grid>
                <Grid item container xs={12} md={4} alignItems="center">
                    <FormControl fullWidth>
                        <InputLabel id="has-image-select-label">
                            Sírhoz van feltöltve kép
                        </InputLabel>
                        <Select
                            labelId="has-image-select-label"
                            id="has-image-select"
                            value={graveSearchParams.hasImage}
                            label="Sírhoz van feltöltve kép"
                            onChange={(event) =>
                                setGraveSearchParams((prevValue) => ({
                                    ...prevValue,
                                    hasImage: event.target.value,
                                }))
                            }
                        >
                            <MenuItem value={2}>Szűrő kikapcsolása</MenuItem>
                            <MenuItem value={1}>Igen</MenuItem>
                            <MenuItem value={0}>Nem</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
                <Button variant="text" sx={{ mt: 1, mb: 2 }} onClick={handleResetFilters}>
                    Szűrők törlése
                </Button>
            </Grid>
        </>
    );
};

export default GraveFilters;
