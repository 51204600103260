import { Grid } from '@mui/material';
import React from 'react';
import MapWrapper from '../components/mapwrapper/MapWrapper';

const PageLayout = ({ children }) => {
    return (
        <Grid container sx={{ height: '100%' }}>
            <Grid
                container
                item
                xs={12}
                md={6}
                spacing={0}
                alignContent="flex-start"
                sx={{ p: 2, height: '100%', overflowY: 'auto' }}
            >
                {children}
            </Grid>
            <Grid container item xs={12} md={6} spacing={0} sx={{ height: '100%' }}>
                <MapWrapper />
            </Grid>
        </Grid>
    );
};

export default PageLayout;
