import { createTheme } from '@mui/material';

const theme = createTheme({
    palette: {},
    shape: {
        borderRadius: 0,
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    '&.basemap-menu': {
                        position: 'absolute',
                        backgroundColor: '#fff',
                        zIndex: 100,
                        borderRadius: '10px',
                        padding: '10px 5px',
                    },
                },
            },
        },
    },
});

export default theme;
