import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MapContextProvider } from 'es-map-widget';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { MAP_OPTIONS } from '../constants';
import { ModalContextProvider } from '../hooks/useModal';
import theme from '../theme';
import { AppContextProvider } from './AppContext';
import { AuthContextProvider } from './AuthProvider';

const Providers = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <AuthContextProvider>
                <MapContextProvider viewOptions={MAP_OPTIONS}>
                    <AppContextProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <SnackbarProvider maxSnack={3}>
                                <ModalContextProvider>{children}</ModalContextProvider>
                            </SnackbarProvider>
                        </LocalizationProvider>
                    </AppContextProvider>
                </MapContextProvider>
            </AuthContextProvider>
        </ThemeProvider>
    );
};

export default Providers;
