import { CssBaseline } from '@mui/material';
import 'es-map-widget/dist/ol-ext.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import Providers from './providers';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Providers>
                <CssBaseline />
                <App />
            </Providers>
        </BrowserRouter>
    </React.StrictMode>
);
