import React, { createContext, useContext, useMemo, useState } from 'react';

const UrnsContext = createContext();
UrnsContext.displayName = 'UrnsContext';

export function UrnsContextProvider({ children }) {
    const [selectedUrn, setSelectedUrn] = useState(null);

    const contextValue = useMemo(
        () => ({
            selectedUrn,
            setSelectedUrn,
        }),
        [selectedUrn, setSelectedUrn]
    );

    return <UrnsContext.Provider value={contextValue}>{children}</UrnsContext.Provider>;
}

export function useUrnsContext() {
    return useContext(UrnsContext);
}
