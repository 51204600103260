import { LAYER_TYPES, Map, PREDEFINED_LAYERS, useMap } from 'es-map-widget';
import React, { useCallback, useEffect } from 'react';
import { MAP_LAYER_NAMES } from '../../constants/mapLayerNames';
import { useAppContext } from '../../providers/AppContext';
import GravesDataprovider from '../mapdata/GravesDataprovider';
import ParcelsDataprovider from '../mapdata/ParcelsDataprovider';
import { TILE_HOST } from '../../config';
import './map.css';
import GeometryDataprovider from '../mapdata/GeometryDataprovider';

/**
 * Application layer for the Map, nothing below this should be exposed to the app and should move
 * to the map component
 * @returns {JSX.Element}
 * @constructor
 */
const MapWrapper = () => {
    const map = useMap();
    const { selectedGraveyard } = useAppContext();
    const { graveyards } = useAppContext();

    const getMapLayerDefinition = useCallback(() => {
        return {
            [MAP_LAYER_NAMES.OSM]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.OSM,
                defaultVisibility: true,
            },
            ...graveyards.reduce(
                (newObject, graveyard) => ({
                    ...newObject,
                    [`GRAVEYARD_${graveyard.id}`]: {
                        type: LAYER_TYPES.TILE,
                        source: PREDEFINED_LAYERS.XYZ,
                        sourceSettings: {
                            url: `${TILE_HOST}${graveyard.tiles}/{z}/{x}/{y}.png`,
                        },
                        defaultVisibility: false,
                    },
                }),
                {}
            ),
            [MAP_LAYER_NAMES.PARCELS]: {
                type: LAYER_TYPES.VECTOR,
                source: ParcelsDataprovider,
                defaultVisibility: true,
                initialLayerData: {
                    ids: [],
                },
            },
            [MAP_LAYER_NAMES.GRAVES]: {
                type: LAYER_TYPES.VECTOR,
                source: GravesDataprovider,
                defaultVisibility: true,
                initialLayerData: {
                    ids: [],
                },
            },
            [MAP_LAYER_NAMES.GEOMETRY]: {
                type: LAYER_TYPES.VECTOR,
                source: GeometryDataprovider,
                defaultVisibility: false,
                initialLayerData: {
                    geom: null,
                },
            },
        };
    }, [graveyards, selectedGraveyard]);

    useEffect(() => {
        function trySetUntilSuccess(tryCount) {
            try {
                map.addPopoverToLayer(MAP_LAYER_NAMES.GRAVES);
                map.addPopoverToLayer(MAP_LAYER_NAMES.PARCELS);
            } catch (error) {
                console.log(error);
                if (tryCount < 10) {
                    setTimeout(() => trySetUntilSuccess(tryCount + 1), 1000);
                }
            }
        }

        trySetUntilSuccess(0);
    }, [map]);

    if (graveyards.length === 0) {
        return null;
    }
    return <Map layers={getMapLayerDefinition()} />;
};

export default MapWrapper;
