import { Button } from '@mui/material';
import React from 'react';

const DrawButton = ({ children, ...otherParams }) => {
    return (
        <Button variant="contained" sx={{ height: '50px', width: '50px', mr: 2 }} {...otherParams}>
            {children}
        </Button>
    );
};

export default DrawButton;
