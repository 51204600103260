import { Box, Toolbar } from '@mui/material';
import { MAP_EVENTS, useMap } from 'es-map-widget';
import React, { useCallback, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Navbar from '../components/header/Navbar';
import { MAP_LAYER_NAMES } from '../constants/mapLayerNames';
import { useAppContext } from '../providers/AppContext';

const AppLayout = () => {
    const map = useMap();
    const { selectedItem, setSelectedItem, selectedGraveyard } = useAppContext();
    const navigate = useNavigate();

    const onSelection = useCallback(
        (mapElement) => {
            if (!mapElement) {
                return;
            }

            if (mapElement.id !== selectedItem?.id || !selectedItem) {
                setSelectedItem({ ...mapElement });
            }

            if (mapElement.type === 'grave') {
                map.setLayerData(MAP_LAYER_NAMES.GRAVES, { ids: [mapElement.id] });
                navigate(`/graves/${mapElement.id}`);
            } else {
                // eslint-disable-next-line no-lonely-if
                if (selectedGraveyard) {
                    try {
                        map.zoomToGeomsExtent(
                            selectedGraveyard.parcels.filter((e) => e.id === mapElement.id)
                        );
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
        },
        [map, setSelectedItem, navigate, selectedGraveyard, selectedItem]
    );

    useEffect(() => {
        const selectionListener = map.addEventListener(MAP_EVENTS.selection, () => {
            onSelection(map.getSelected());
        });
        return () => map.removeEventListener(MAP_EVENTS.selection, selectionListener);
    }, [map, onSelection]);

    return (
        <>
            <Navbar />
            <Box component="main" sx={{ height: '100vh' }}>
                <Toolbar />
                <Box sx={{ height: 'calc(100% - 64px)' }}>
                    <Outlet />
                </Box>
            </Box>
        </>
    );
};

export default AppLayout;
