import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { MODAL_TYPE } from '../constants';
import { api } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';

const PhotoModal = ({ showModal, file, graveId, onSuccess }) => {
    const { notifyError } = useNotify();
    const handleClose = () => {
        showModal(MODAL_TYPE.NONE);
    };

    const handleRotatePhoto = useCallback(
        (angle) =>
            api.images
                .rotateImages({ graveId, imageId: file.id, angle })
                .then(() => onSuccess())
                .catch((err) => {
                    notifyError(err);
                    onSuccess();
                }),
        [graveId, file, notifyError, onSuccess]
    );

    return (
        <Dialog open onClose={handleClose} maxWidth="lg">
            <DialogContent
                sx={{ p: 2, display: 'flex', justifyContent: 'center', position: 'relative' }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <img
                            src={
                                file.photoPath.includes('photos/')
                                    ? file.photoPath.replace(/photos\//g, '/content/photos_normal/')
                                    : `/content/photos_normal/${file.photoPath}`
                            }
                            alt=""
                            width="100%"
                            height="100%"
                        />
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            size="small"
                            sx={{
                                position: 'absolute',
                                top: 20,
                                right: 20,
                            }}
                        >
                            <Close />
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography variant="h6">Forgatás</Typography>
                        <Button
                            onClick={() => handleRotatePhoto(0)}
                            variant="contained"
                            size="small"
                            sx={{
                                mr: 1,
                            }}
                        >
                            <b>0°</b>
                        </Button>
                        <Button
                            onClick={() => handleRotatePhoto(90)}
                            variant="contained"
                            size="small"
                            sx={{
                                mr: 1,
                            }}
                        >
                            <b>90°</b>
                        </Button>
                        <Button
                            onClick={() => handleRotatePhoto(180)}
                            variant="contained"
                            size="small"
                            sx={{
                                mr: 1,
                            }}
                        >
                            <b>180°</b>
                        </Button>
                        <Button
                            onClick={() => handleRotatePhoto(270)}
                            variant="contained"
                            size="small"
                            sx={{
                                mr: 1,
                            }}
                        >
                            <b>270°</b>
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default PhotoModal;
