import { useCallback, useEffect, useState } from 'react';
import { LOGGED_IN_USER_KEY } from '../config';
import { Api } from '../services/APIService';
import useNotify from './useNotify';

const api = new Api({
    baseURL: '/api',
    securityWorker: (token) => (token ? { headers: { Authorization: `Bearer ${token}` } } : {}),
    headers: {
        // Set default content type
        put: { 'Content-Type': 'application/json' },
        post: { 'Content-Type': 'application/json' },
        patch: { 'Content-Type': 'application/json' },
    },
});

// Load token from localStorage
const user = JSON.parse(localStorage.getItem(LOGGED_IN_USER_KEY));
if (user) {
    api.setSecurityData(user.token);
}

const useApi = (call, extraDeps = [], initialValue = null, mappingFn = (x) => x, stop = false) => {
    const [result, setResult] = useState(initialValue);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { notifyError } = useNotify();

    const handleError = useCallback(
        (err) => {
            console.error(err);
            let e = {};
            if (err.response) {
                e = {
                    status: err.response.status,
                    message: err.response.data.message || 'Hiba történt a kérés teljesítése során.',
                };
            } else if (err.request) {
                e = { status: null, message: 'A kérés túllépte a megadott időlimitet' };
            } else {
                e = { status: null, message: err.message };
            }
            setError(e);
            notifyError(e);
        },
        [setError]
    );

    useEffect(() => {
        const controller = new AbortController();

        if (stop) {
            return () => controller.abort();
        }

        call(api, {
            signal: controller.signal,
        })
            .then((res) => {
                setResult(mappingFn(res.data));
                setError(null);
                setLoading(false);
            })
            .catch((err) => {
                if (!controller.signal.aborted) {
                    handleError(err);
                    setLoading(false);
                    setResult(null);
                }
            });

        return () => controller.abort();
    }, [api, setResult, setLoading, handleError, ...extraDeps]);

    return [result, loading, error, setResult];
};

export { api, useApi };
