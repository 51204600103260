import { useCallback, useState } from 'react';

const useSorting = (defaultSorting) => {
    const [defaultSort = undefined, defaultSortDir = undefined] = defaultSorting ?? [];
    const [sort, setSort] = useState(defaultSort);
    const [sortDir, setSortDir] = useState(defaultSortDir);

    const setSorting = useCallback(
        (newSort) => {
            if (sort === newSort) {
                setSortDir((dir) => {
                    if (dir === 'asc') {
                        return 'desc';
                    }
                    if (dir === 'desc') {
                        return undefined;
                    }
                    return 'asc';
                });
            } else {
                setSort(newSort);
            }
        },
        [sort, setSort, setSortDir]
    );

    return { sort, sortDir, setSorting };
};

export default useSorting;
