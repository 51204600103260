import { Box, TableCell, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import React from 'react';

function SortableTableCell({
    order = false,
    label,
    onClick,
    direction,
    style = {},
    ...otherParams
}) {
    return (
        <TableCell {...otherParams} style={{ ...style, cursor: 'pointer' }}>
            <TableSortLabel active={order !== false} direction={direction} onClick={onClick}>
                {label}
                {order ? (
                    <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                ) : null}
            </TableSortLabel>
        </TableCell>
    );
}

export default SortableTableCell;
