import { Alert, Grid, LinearProgress, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api, useApi } from '../../../../hooks/useApi';
import useNotify from '../../../../hooks/useNotify';
import { useUrnsContext } from '../UrnsContext';
import GravePhoto from './GravePhoto';
import GravePhotoUpload from './GravePhotoUpload';

const PhotoUploadForm = () => {
    const { graveId } = useParams();
    const { selectedUrn } = useUrnsContext();
    const { notifySuccess, notifyError } = useNotify();
    const [reloadFlag, setReloadFlag] = useState(false);

    const [gravePhotos, loading, error] = useApi(
        (API, params) =>
            API.images.getImages({ graveId, urnId: selectedUrn?.id || undefined }, params),
        [graveId, selectedUrn, reloadFlag]
    );

    const handleFileChange = useCallback(
        (event) => {
            event.preventDefault();
            if (!event.target.files || !event.target.files[0]) {
                return;
            }
            api.images
                .addImages(
                    {
                        graveId,
                        urnId: selectedUrn?.id || undefined,
                    },
                    {
                        file: event.target.files[0],
                    }
                )
                .then(() => {
                    notifySuccess('A kép mentése sikeres.');
                    setReloadFlag((p) => !p);
                })
                .catch((err) => {
                    notifyError(err);
                    setReloadFlag((p) => !p);
                });
        },
        [graveId, selectedUrn, notifySuccess, notifyError, setReloadFlag]
    );

    if (loading) {
        return (
            <Grid item xs={12}>
                <LinearProgress />
            </Grid>
        );
    }

    if (error) {
        return (
            <Grid item xs={12}>
                <Alert severity="error">Hiba a képek betöltése során</Alert>
            </Grid>
        );
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6">Sírfotók</Typography>
            </Grid>

            <Grid container item xs={12} spacing={2}>
                {(gravePhotos || []).map((file, idx) => (
                    <GravePhoto
                        // eslint-disable-next-line react/no-array-index-key
                        key={idx}
                        file={file}
                        setReloadFlag={setReloadFlag}
                    />
                ))}
                <Grid container item xs={12} md={6} lg={4}>
                    <GravePhotoUpload onChange={(event) => handleFileChange(event)} />
                </Grid>
            </Grid>
        </>
    );
};

export default PhotoUploadForm;
