import { Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
    const navigate = useNavigate();
    return (
        <Container>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ pt: '100px' }}
            >
                <Grid item>
                    <Typography variant="h6">A keresett oldal nem található</Typography>
                </Grid>
                <Grid item>
                    <Button variant="text" onClick={() => navigate('/')}>
                        Főoldal
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default NotFoundPage;
