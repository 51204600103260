import { Delete, Save } from '@mui/icons-material';
import { Button, Grid, IconButton, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MODAL_TYPE } from '../../../../constants';
import { api } from '../../../../hooks/useApi';
import { useModals } from '../../../../hooks/useModal';
import useNotify from '../../../../hooks/useNotify';
import { useUrnsContext } from '../UrnsContext';

const dateRequiredError = {
    error: true,
    text: 'A mező kitöltése kötelező',
};

const initialDateValidation = { error: false, text: '' };

const GravePhoto = ({ file, setReloadFlag }) => {
    const { graveId } = useParams();
    const { selectedUrn } = useUrnsContext();
    const { showModal } = useModals();
    const { notifySuccess, notifyError } = useNotify();
    const [date, setDate] = useState(file.photoTakenDate);
    const [isDateDisabled, setIsDateDisabled] = useState(true);
    const [dateValidation, setDateValidation] = useState(initialDateValidation);

    const updatePhotoDate = useCallback(() => {
        if (!date) {
            setDateValidation(dateRequiredError);
            return;
        }
        api.images
            .updateImages(
                {
                    imageId: file.id,
                    graveId,
                    urnId: selectedUrn?.id || undefined,
                },
                {
                    taken: date ? format(date, 'yyyy-MM-dd') : null,
                }
            )
            .then(() => {
                notifySuccess('A kép mentése sikeres.');
                setIsDateDisabled(true);
                setDateValidation(initialDateValidation);
            })
            .catch((err) => {
                notifyError(err);
                setIsDateDisabled(false);
                setDateValidation(dateRequiredError);
            });
    }, [graveId, selectedUrn, date, file, api, notifySuccess, notifyError, setDateValidation]);

    const handleDeletePhoto = useCallback(
        (event) => {
            event.stopPropagation();
            showModal(MODAL_TYPE.CONFIRM_MODAL, {
                onClick: () =>
                    api.images
                        .deleteImages({ graveId, imageId: file.id })
                        .then(() => notifySuccess('A kép törlése sikeres'))
                        .catch((err) => notifyError(err))
                        .then(() => setReloadFlag((p) => !p)),
                title: 'Biztosan törölni szeretné a képet?',
            });
        },
        [showModal, file, graveId, api, notifySuccess, notifyError, setReloadFlag]
    );

    const onSuccess = useCallback(() => {
        setReloadFlag((p) => !p);
        showModal(MODAL_TYPE.NONE);
    }, [setReloadFlag]);

    return (
        <Grid item container xs={12} md={6} lg={4} key={file.name}>
            <Grid
                item
                xs={12}
                sx={{
                    height: 150,
                    position: 'relative',
                    mb: 2,
                    cursor: 'pointer',
                }}
                onClick={() => showModal(MODAL_TYPE.PHOTO_MODAL, { file, graveId, onSuccess })}
            >
                <img
                    src={
                        file.photoPath.includes('photos/')
                            ? file.photoPath.replace(/photos\//g, '/content/photos_preview/')
                            : `/content/photos_preview/${file.photoPath}`
                    }
                    alt=""
                    width="100%"
                    height="150"
                    style={{ objectFit: 'cover' }}
                />
                <div
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        right: 10,
                    }}
                >
                    <Button
                        onClick={(event) => handleDeletePhoto(event)}
                        variant="contained"
                        size="small"
                        sx={{
                            minWidth: 'unset',
                            '& > svg': {
                                color: 'white',
                            },
                        }}
                    >
                        <Delete />
                    </Button>
                </div>
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={10}>
                    <DatePicker
                        label="Fotó dátuma"
                        inputFormat="yyyy-MM-dd"
                        value={date}
                        onChange={(newDate) => {
                            setDate(newDate);
                            setIsDateDisabled(false);
                            let formattedDate;
                            try {
                                formattedDate = format(newDate, 'yyyy-MM-dd');
                            } catch (e) {
                                setDateValidation(dateRequiredError);
                            }
                            if (!formattedDate) {
                                setDateValidation(dateRequiredError);
                                return;
                            }

                            const re =
                                /^(?!0000)[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/i;
                            const isDate = re.test(formattedDate);
                            if (!isDate) {
                                setDateValidation(dateRequiredError);
                            } else {
                                setDateValidation(initialDateValidation);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                error={dateValidation.error}
                                helperText={dateValidation.text}
                            />
                        )}
                    />
                </Grid>
                <Grid item container xs={2} justifyContent="center" alignItems="center">
                    <IconButton
                        onClick={updatePhotoDate}
                        disabled={isDateDisabled || dateValidation.error}
                    >
                        <Save />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GravePhoto;
