import { Autocomplete, TextField } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MAP_LAYER_NAMES } from '../../constants/mapLayerNames';
import { api } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import { useAppContext } from '../../providers/AppContext';

const GraveyardSearch = () => {
    const map = useMap();
    const { graveId } = useParams();
    const { notifyError } = useNotify();
    const {
        resetMap,
        graveyards,
        setGraveyards,
        selectedGraveyard,
        setSelectedGraveyard,
        setGraveyardVisibility,
        zoomToSelectedGraveyard,
    } = useAppContext();
    const navigate = useNavigate();

    const handleGraveyardSelect = (_, value, reason) => {
        if (reason === 'clear') {
            setGraveyardVisibility();
            resetMap();
        } else {
            setGraveyardVisibility(value.id, `GRAVEYARD_${value.id}`);
        }
        setSelectedGraveyard(value);
        navigate('/');
    };

    useEffect(() => {
        if (!graveId) {
            return;
        }

        function trySetUntilSuccess(tryCount) {
            try {
                setGraveyardVisibility(selectedGraveyard.id, `GRAVEYARD_${selectedGraveyard.id}`);
            } catch (error) {
                console.log(error);
                if (tryCount < 5) {
                    setTimeout(() => trySetUntilSuccess(tryCount + 1), 100);
                }
            }
        }

        trySetUntilSuccess(0);
    }, [map, selectedGraveyard, setGraveyardVisibility]);

    useEffect(() => {
        if (!selectedGraveyard || !selectedGraveyard.geom) {
            resetMap();
            return;
        }

        function trySetUntilSuccess(tryCount) {
            try {
                map.setLayerData(MAP_LAYER_NAMES.PARCELS, {
                    ids: selectedGraveyard.parcels.map((p) => p.id),
                });
                map.setLayerVisibility(MAP_LAYER_NAMES.PARCELS, true);
                setGraveyardVisibility(selectedGraveyard.id, `GRAVEYARD_${selectedGraveyard.id}`);

                if (selectedGraveyard?.geom && !graveId) {
                    zoomToSelectedGraveyard();
                }
            } catch (error) {
                console.log(error);
                if (tryCount < 5) {
                    setTimeout(() => trySetUntilSuccess(tryCount + 1), 100);
                }
            }
        }

        trySetUntilSuccess(0);
    }, [map, selectedGraveyard, resetMap, setGraveyardVisibility, zoomToSelectedGraveyard]);

    useEffect(() => {
        const controller = new AbortController();

        api.graveyards
            .getGraveyards({}, { signal: controller.signal })
            .then((res) => {
                setGraveyards(res.data);
                if (res.data.length === 1) {
                    setSelectedGraveyard(res.data[0]);
                }
            })
            .catch((err) => notifyError(err));

        return () => {
            controller.abort();
        };
    }, [setGraveyards, setSelectedGraveyard]);

    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={graveyards}
            value={selectedGraveyard}
            getOptionLabel={(e) => e.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={handleGraveyardSelect}
            sx={{
                width: 300,
                '& .MuiInputBase-root': {
                    py: 0,
                },
            }}
            renderInput={(params) => (
                <TextField type="text" {...params} label="Válasszon temetőt" />
            )}
        />
    );
};

export default GraveyardSearch;
