import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const AccessDenied = () => {
    const navigate = useNavigate();
    return (
        <Grid
            sx={{ height: '100%' }}
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
        >
            <Grid item textAlign="center">
                <Typography variant="h6">
                    Nincs jogosultságod ennek az oldalnak a megtekintéséhez.
                </Typography>
            </Grid>
            <Grid item>
                <Button variant="text" onClick={() => navigate('/')}>
                    Kezdőlap
                </Button>
            </Grid>
        </Grid>
    );
};

export default AccessDenied;
