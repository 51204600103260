import { Fill, Stroke } from 'es-map-widget';

const textBase = {
    text: '',
    font: '12px sans-serif',
    fill: new Fill({ color: '#000' }),
    stroke: new Stroke({
        color: '#fff',
        width: 2,
    }),
};

const styles = {
    parcel: {
        stroke: new Stroke({
            color: '#ffff00',
            width: 3,
        }),
        fill: new Fill({
            color: [255, 255, 0, 0.5],
        }),
    },
};

export { textBase, styles };
