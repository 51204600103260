// eslint-disable-next-line import/prefer-default-export
export const MAP_LAYER_NAMES = {
    OSM: 'OSM',
    PARCELS: 'PARCELS',
    GRAVES: 'GRAVES',
    GEOMETRY: 'GEOMETRY',
    GRAVEYARD_1: 'GRAVEYARD_1',
    GRAVEYARD_2: 'GRAVEYARD_2',
    GRAVEYARD_3: 'GRAVEYARD_3',
    GRAVEYARD_4: 'GRAVEYARD_4',
    GRAVEYARD_5: 'GRAVEYARD_5',
    GRAVEYARD_6: 'GRAVEYARD_6',
    GRAVEYARD_7: 'GRAVEYARD_7',
    GRAVEYARD_8: 'GRAVEYARD_8',
    GRAVEYARD_9: 'GRAVEYARD_9',
    GRAVEYARD_10: 'GRAVEYARD_10',
    GRAVEYARD_11: 'GRAVEYARD_11',
    GRAVEYARD_12: 'GRAVEYARD_12',
};
