import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { LOGGED_IN_USER_KEY } from '../config';

const AuthContext = createContext();
AuthContext.displayName = 'AuthContext';

function getUserFromStorage() {
    try {
        return JSON.parse(localStorage.getItem(LOGGED_IN_USER_KEY));
    } catch (err) {
        console.error(err);
        return false;
    }
}

export function AuthContextProvider({ children }) {
    const [user, setUserInternal] = useState(getUserFromStorage);

    const setUser = useCallback(
        (newUser) => {
            setUserInternal(newUser);
            if (newUser === null) {
                localStorage.removeItem(LOGGED_IN_USER_KEY);
            } else {
                localStorage.setItem(LOGGED_IN_USER_KEY, JSON.stringify(newUser));
            }
        },
        [setUserInternal]
    );

    const logoutUser = useCallback(() => {
        setUser(null);
    }, [setUser]);

    useEffect(() => {
        const checkUser = () => {
            const newUser = getUserFromStorage();
            if (user === newUser) {
                return;
            }
            setUser(user);
            if (user === null) {
                setUser(null);
            }
        };
        window.addEventListener('storage', checkUser);
        return () => {
            window.removeEventListener('storage', checkUser);
        };
    }, [user, setUser]);

    const contextValue = useMemo(
        () => ({ user, setUser, logoutUser }),
        [user, setUser, logoutUser]
    );

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return useContext(AuthContext);
}
