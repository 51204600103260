import { Close, Delete, Done } from '@mui/icons-material';
import {
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import SortableTableCell from '../../../components/table/SortableTableCell';
import { MODAL_TYPE } from '../../../constants';
import { MAP_LAYER_NAMES } from '../../../constants/mapLayerNames';
import { api } from '../../../hooks/useApi';
import { useModals } from '../../../hooks/useModal';
import useNotify from '../../../hooks/useNotify';
import { useAppContext } from '../../../providers/AppContext';

const GravesTable = ({
    graves,
    page,
    count,
    loading,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    setReloadFlag,
    setPage,
    sort,
    sortDir,
    setSorting,
}) => {
    const map = useMap();
    const { showModal } = useModals();
    const { notifySuccess, notifyError } = useNotify();
    const { setSelectedItem, selectedGraveyard } = useAppContext();
    const navigate = useNavigate();

    const deleteGrave = useCallback(
        (graveyardId, graveId) =>
            api.admin
                .deleteGrave(graveyardId, graveId)
                .then(() => {
                    notifySuccess('Sírhely törlése sikeres');
                    map.setLayerData(MAP_LAYER_NAMES.GRAVES, { ids: [] });
                    setReloadFlag((pv) => !pv);
                })
                .catch((err) => {
                    notifyError(err);
                }),
        [notifyError, notifySuccess, setReloadFlag]
    );

    const handleDeleteGrave = (event, graveyardId, graveId) => {
        event.stopPropagation();
        showModal(MODAL_TYPE.CONFIRM_MODAL, {
            onClick: () => deleteGrave(graveyardId, graveId),
            title: 'Biztosan törölni szeretné a sírhelyet?',
        });
    };

    const handleClick = useCallback(
        (grave) => {
            map.setLayerData(MAP_LAYER_NAMES.GRAVES, { ids: [grave.id] });
            map.zoomToGeomsExtent([{ geom: grave.geom }]);
            setSelectedItem({ ...grave });
            navigate(`/graves/${grave.id}`);
        },
        [map, setSelectedItem, navigate]
    );

    return (
        <Paper sx={{ width: '100%', my: 2 }}>
            <Typography sx={{ px: 2, py: 1 }} variant="h6" id="tableTitle" component="div">
                Sírhelyek
            </Typography>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Parcella</TableCell>
                            <TableCell align="right">Sor</TableCell>
                            <SortableTableCell
                                align="right"
                                label="Pozíció"
                                order={sort === 'pos' ? sortDir : false}
                                onClick={() => {
                                    setSorting('pos');
                                    setPage(0);
                                }}
                                direction={sortDir}
                            />
                            {selectedGraveyard?.id === 6 && (
                                <TableCell align="right">Szekció</TableCell>
                            )}
                            <TableCell align="right">Lejárat</TableCell>
                            <TableCell align="right">Kép</TableCell>
                            <TableCell align="right">Törlés</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && selectedGraveyard && (
                            <TableRow>
                                <TableCell colSpan={6} sx={{ p: 0, m: 0 }}>
                                    <LinearProgress />
                                </TableCell>
                            </TableRow>
                        )}
                        {graves.length === 0 && !selectedGraveyard && (
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Typography>Válasszon temetőt!</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {graves.map((grave) => (
                            <TableRow
                                key={grave.id}
                                hover
                                role="checkbox"
                                onClick={() => handleClick(grave)}
                                sx={{ cursor: 'pointer' }}
                            >
                                <TableCell align="left">{grave.parcel || ''}</TableCell>
                                <TableCell align="right">{grave.row}</TableCell>
                                <TableCell align="right">{grave.pos}</TableCell>
                                {selectedGraveyard?.id === 6 && (
                                    <TableCell align="right">{grave.section}</TableCell>
                                )}
                                <TableCell align="right">{grave.graveValidDate}</TableCell>
                                <TableCell align="right">
                                    {grave.hasImage ? <Done /> : <Close />}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        disabled={grave.type === 'urn'}
                                        onClick={(event) =>
                                            handleDeleteGrave(event, selectedGraveyard.id, grave.id)
                                        }
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default GravesTable;
