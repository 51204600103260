import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppLayout from './layouts/AppLayout';
import PageLayout from './layouts/PageLayout';
import AccessDenied from './pages/AccessDenied';
import EditGravePage from './pages/EditGravePage';
import Graves from './pages/Graves';
import LoginPage from './pages/LoginPage';
import NotFound from './pages/NotFoundPage';
import ProtectedPage from './pages/ProtectedPage';

const App = () => {
    return (
        <Routes>
            <Route element={<AppLayout />}>
                <Route path="/login" element={<LoginPage />} />
                <Route
                    path="/"
                    element={
                        <ProtectedPage>
                            <PageLayout>
                                <Graves />
                            </PageLayout>
                        </ProtectedPage>
                    }
                />
                <Route
                    path="/graves/new"
                    element={
                        <ProtectedPage>
                            <PageLayout>
                                <EditGravePage />
                            </PageLayout>
                        </ProtectedPage>
                    }
                />
                <Route
                    path="/graves/:graveId"
                    element={
                        <ProtectedPage>
                            <PageLayout>
                                <EditGravePage />
                            </PageLayout>
                        </ProtectedPage>
                    }
                />
                <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/accessdenied" element={<AccessDenied />} />
        </Routes>
    );
};

export default App;
