const initialDeceasedValues = {
    name: '',
    birthDate: null,
    deathDate: null,
};

const initialNewGraveValues = {
    parcel: null,
    row: '',
    pos: '',
    geom: null,
    section: null,
    description: null
};

export { initialDeceasedValues, initialNewGraveValues };
