import { Tooltip, Box } from '@mui/material';
import React from 'react';
import { useUrnsContext } from './UrnsContext';

const UrnBox = ({ urn, filteredUrns }) => {
    const { selectedUrn, setSelectedUrn } = useUrnsContext();
    return (
        <Tooltip title={`${urn.xLabel}-${urn.yLabel}`} placement="top" disableInteractive>
            <Box
                key={urn.X + urn.Y}
                sx={{
                    position: 'absolute',
                    top: `${urn.Y * 35}px`,
                    left: `${urn.X * 35}px`,
                    background: urn.deads.length === 0 ? '#eee' : '#ddd',
                    height: '30px',
                    width: '30px',
                    cursor: 'pointer',
                    border: (theme) =>
                        filteredUrns.includes(urn.id)
                            ? '#D99D26 2px solid'
                            : `${
                                  selectedUrn?.id === urn.id && theme.palette.primary.main
                              } 2px solid`,
                    '&:hover': {
                        border: (theme) => `2px solid ${theme.palette.primary.main}`,
                    },
                }}
                onClick={() => {
                    if (selectedUrn?.id === urn.id) {
                        setSelectedUrn(null);
                        return;
                    }
                    setSelectedUrn(urn);
                }}
            />
        </Tooltip>
    );
};

export default UrnBox;
