import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { api } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import { useAppContext } from '../providers/AppContext';
import GraveFilters from './components/gravefilters/GraveFilters';
import GravesTable from './components/graves/GravesTable';
import useSorting from '../hooks/useSorting';

const initialGraveSearchParams = Object.freeze({
    parcel: '',
    section: '',
    row: '',
    pos: '',
    hasImage: 2,
    leaseStartDate: '',
    leaseEndDate: '',
});

const Graves = () => {
    const { notifyError } = useNotify();
    const { selectedGraveyard } = useAppContext();
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [graves, setGraves] = useState([]);
    const [graveSearchParams, setGraveSearchParams] = useState({ ...initialGraveSearchParams });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [reloadFlag, setReloadFlag] = useState(false);
    const { sort, sortDir, setSorting } = useSorting(['pos']);

    useEffect(() => {
        if (!selectedGraveyard) {
            setGraves([]);
            return;
        }

        const controller = new AbortController();
        setLoading(true);
        api.admin
            .searchGraves(
                {
                    graveyardId: selectedGraveyard?.id,
                    offset: page * rowsPerPage,
                    limit: rowsPerPage,
                    sort: !sortDir ? undefined : sort,
                    sortDir,
                },
                {
                    parcel: graveSearchParams.parcel || undefined,
                    section: graveSearchParams.section || undefined,
                    row: graveSearchParams.row || undefined,
                    pos: graveSearchParams.pos || undefined,
                    hasImage:
                        graveSearchParams.hasImage === 2 ? null : !!graveSearchParams.hasImage,
                    leaseStartDate: graveSearchParams.leaseStartDate || undefined,
                    leaseEndDate: graveSearchParams.leaseEndDate || undefined,
                },
                { signal: controller.signal }
            )
            .then((res) => {
                setCount(res.data.count);
                setGraves(res.data.items);
                if (res.data.count < page * rowsPerPage) {
                    setPage(0);
                }
                setLoading(false);
            })
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    notifyError(err);
                    setLoading(false);
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [
        graveSearchParams,
        selectedGraveyard,
        page,
        rowsPerPage,
        reloadFlag,
        sort,
        sortDir,
        notifyError,
        setLoading,
        setCount,
        setGraves,
    ]);

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Grid container item xs={12}>
            <GraveFilters
                initialGraveSearchParams={initialGraveSearchParams}
                graveSearchParams={graveSearchParams}
                setGraveSearchParams={setGraveSearchParams}
            />
            <Grid item xs={12}>
                <GravesTable
                    graves={graves || []}
                    page={page}
                    count={count}
                    loading={loading}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    setReloadFlag={setReloadFlag}
                    setPage={setPage}
                    sort={sort}
                    sortDir={sortDir}
                    setSorting={setSorting}
                />
            </Grid>
        </Grid>
    );
};

export default Graves;
