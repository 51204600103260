import { Grid, LinearProgress } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MAP_LAYER_NAMES } from '../constants/mapLayerNames';
import { api } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import { useAppContext } from '../providers/AppContext';
import DeceasedTable from './components/DeceasedTable';
import EditGraveForm from './components/editgrave/EditGraveForm';
import PhotoUploadForm from './components/editgrave/photouploadform/PhotoUploadForm';
import Urns from './components/editgrave/Urns';
import { UrnsContextProvider } from './components/editgrave/UrnsContext';

const EditGravePage = () => {
    const map = useMap();
    const { graveId } = useParams();
    const { notifyError } = useNotify();
    const { setSelectedItem, selectedGraveyard, reloadGravesLayer } = useAppContext();
    const [loading, setLoading] = useState(true);
    const [editGraveValues, setEditGraveValues] = useState(null);

    useEffect(() => {
        if (!graveId || !selectedGraveyard) {
            setLoading(false);
            return;
        }

        const controller = new AbortController();
        setLoading(true);
        api.admin
            .getGrave(selectedGraveyard?.id, graveId, { signal: controller.signal })
            .then((res) => {
                setEditGraveValues(res.data);
                setSelectedItem({ ...res.data });
                map.setLayerData(MAP_LAYER_NAMES.GRAVES, { ids: [res.data.id] });
                map.zoomToGeomsExtent([{ geom: res.data.geom }]);
                setLoading(false);
            })
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    notifyError(err);
                }
                setLoading(false);
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [api, graveId, selectedGraveyard, setEditGraveValues, setLoading, reloadGravesLayer]);

    return (
        <UrnsContextProvider>
            <Grid container item xs={12}>
                {loading && graveId && <LinearProgress />}
                {!loading && (
                    <>
                        <EditGraveForm editGraveValues={editGraveValues} />
                        {graveId && editGraveValues?.urns?.length === 0 && (
                            <Grid item xs={12} sx={{ pb: 2 }}>
                                <DeceasedTable deceased={editGraveValues?.deads || []} />
                                <PhotoUploadForm />
                            </Grid>
                        )}
                    </>
                )}
                <Urns editGraveValues={editGraveValues} />
            </Grid>
        </UrnsContextProvider>
    );
};

export default EditGravePage;
