import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useApi } from '../../hooks/useApi';
import { useAppContext } from '../../providers/AppContext';

const SectionSelect = ({ label, value, setValue }) => {
    const { selectedGraveyard } = useAppContext();
    const graveyardId = selectedGraveyard?.id;
    const [sections, loading] = useApi(
        (api, params) => api.admin.getSections(graveyardId, params),
        [graveyardId],
        [],
        (x) => x,
        !graveyardId
    );

    return (
        <FormControl fullWidth>
            <InputLabel id="section-label">{label}</InputLabel>
            <Select
                labelId="section-label"
                id="section-select"
                label="Szekció"
                value={value || ''}
                onChange={setValue}
            >
                {loading ? (
                    <CircularProgress />
                ) : (
                    (sections || []).map((section) => (
                        <MenuItem key={section} value={section}>
                            {section || '-'}
                        </MenuItem>
                    ))
                )}
            </Select>
        </FormControl>
    );
};

export default SectionSelect;
