import * as Yup from 'yup';

const graveValidationSchema = Yup.object({
    row: Yup.string().required('A mező kitöltése kötelező'),
    pos: Yup.string().required('A mező kitöltése kötelező'),
    section: Yup.string().nullable(),
});

// eslint-disable-next-line import/prefer-default-export
export { graveValidationSchema };
