import { LogoutRounded } from '@mui/icons-material';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { useMap } from 'es-map-widget';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MAP_LAYER_NAMES } from '../../constants/mapLayerNames';
import { useAuth } from '../../providers/AuthProvider';
import GraveyardSearch from './GraveyardSearch';

const Navbar = () => {
    const map = useMap();
    const navigate = useNavigate();
    const { user, logoutUser } = useAuth();

    const handleLogout = () => {
        logoutUser(null);
        navigate('/');
    };

    return (
        <AppBar color="primary">
            <Toolbar>
                <Typography
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                        map.drawCancel();
                        map.setLayerData(MAP_LAYER_NAMES.GEOMETRY, { geom: null });
                        map.setLayerVisibility(MAP_LAYER_NAMES.GEOMETRY, false);
                        navigate('/');
                    }}
                >
                    enviMAP Temető
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                {user && (
                    <>
                        <GraveyardSearch />
                        <Typography sx={{ fontWeight: 'bold', mx: 1 }}>{user.name}</Typography>
                        <IconButton onClick={handleLogout}>
                            <LogoutRounded />
                        </IconButton>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
