import { Style } from 'es-map-widget';
import { useEffect } from 'react';
import { styles } from '../mapwrapper/MapWrapperStyles';

const GeometryDataprovider = ({ layerData, setSource }) => {
    const addStyle = (sourceObject) => ({
        ...sourceObject,
        style: new Style({
            ...styles.parcel,
        }),
    });

    useEffect(() => {
        if (!layerData.geom) {
            setSource(null);
            return;
        }

        setSource(addStyle(layerData.geom));
    }, [layerData, setSource, addStyle]);

    return null;
};

export default GeometryDataprovider;
