import { Button, CircularProgress, Dialog, DialogTitle, Grid, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { MODAL_TYPE } from '../constants';
import { api } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import { useAppContext } from '../providers/AppContext';

const initialDeceasedValues = {
    description: null,
    link: {
        text: '',
        url: '',
    },
};

const validationSchema = Yup.object({
    description: Yup.string().nullable(),
    link: Yup.object().shape(
        {
            url: Yup.string().when('text', {
                is: (text) => Boolean(text),
                then: Yup.string().required('Kötelező kitölteni, ha a link szövege ki van töltve'),
            }),
            text: Yup.string().when('url', {
                is: (url) => Boolean(url),
                then: Yup.string().required('Kötelező kitölteni, ha a link url-je ki van töltve'),
            }),
        },
        [
            ['text', 'url'],
            ['url', 'text'],
        ]
    ).nullable(),
});

const EditDeceasedModal = ({ showModal, deceased }) => {
    const { notifySuccess, notifyError } = useNotify();
    const { setReloadGravesLayer } = useAppContext();
    const handleClose = () => {
        showModal(MODAL_TYPE.NONE);
    };

    const onSubmit = (values, { setSubmitting }) => {
        api.admin
            .saveDeceased(deceased.id, {
                description: values.description,
                link: values.link,
            })
            .then(() => notifySuccess('Elhunyt személy adatainak módosítása sikeres'))
            .catch((err) => notifyError(err))
            .finally(() => {
                setSubmitting(false);
                setReloadGravesLayer((p) => !p);
                handleClose();
            });
    };

    return (
        <Dialog open onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Elhunythoz tartozó mezők szerkesztése</DialogTitle>
            <Formik
                initialValues={deceased || initialDeceasedValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {({ submitForm, isSubmitting, touched, errors, values, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={2} sx={{ p: 2 }}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Link szövege"
                                    fullWidth
                                    value={values.link?.text || ''}
                                    onChange={(event) =>
                                        setFieldValue('link.text', event.target.value)
                                    }
                                    error={!!errors.link?.text}
                                    helperText={errors.link?.text ? `${errors.link?.text}` : null}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Link url-je"
                                    fullWidth
                                    value={values.link?.url || ''}
                                    onChange={(event) =>
                                        setFieldValue('link.url', event.target.value)
                                    }
                                    error={!!errors.link?.url}
                                    helperText={errors.link?.url ? `${errors.link?.url}` : null}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    label="Leírás"
                                    multiline
                                    minRows={1}
                                    maxRows={4}
                                    fullWidth
                                    value={values.description || ''}
                                    onChange={(event) =>
                                        setFieldValue('description', event.target.value)
                                    }
                                    error={touched.description && !!errors.description}
                                    helperText={
                                        touched.description && errors.description
                                            ? `${errors.description}`
                                            : null
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                xs={12}
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Button
                                    onClick={handleClose}
                                    disabled={isSubmitting}
                                    sx={{ mr: 1 }}
                                >
                                    Mégse
                                </Button>
                                <Button variant="contained" onClick={submitForm}>
                                    {isSubmitting ? <CircularProgress /> : 'Mentés'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default EditDeceasedModal;
