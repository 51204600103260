import { Edit } from '@mui/icons-material';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import React from 'react';
import { MODAL_TYPE } from '../../constants';
import { useModals } from '../../hooks/useModal';

const DeceasedTable = ({ deceased, forceReload, pagination = false }) => {
    const { showModal } = useModals();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Paper sx={{ width: '100%', mt: 2 }}>
            <Typography sx={{ px: 2, py: 1 }} variant="h6" id="tableTitle" component="div">
                Elhunytak
            </Typography>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Név</TableCell>
                            <TableCell align="right">Születési idő</TableCell>
                            <TableCell align="right">Halálozás ideje</TableCell>
                            <TableCell align="right">Temetés időpontja</TableCell>
                            <TableCell align="right">Sírhely érvényessége</TableCell>
                            <TableCell align="right" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {deceased.map((d) => (
                            <TableRow key={d.id} hover>
                                <TableCell align="left">{d.name}</TableCell>
                                <TableCell align="right">{d.birthDate || '-'}</TableCell>
                                <TableCell align="right">{d.deathDate || '-'}</TableCell>
                                <TableCell align="right">{d.funeralTime || '-'}</TableCell>
                                <TableCell align="right">{d.graveValidUntil || '-'}</TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            showModal(MODAL_TYPE.EDIT_DECEASED_MODAL, {
                                                deceased: d,
                                            })
                                        }
                                    >
                                        <Edit />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {pagination && (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={deceased.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </Paper>
    );
};

export default DeceasedTable;
