import { AddAPhoto } from '@mui/icons-material';
import { Box, Grid, InputBase, InputLabel } from '@mui/material';
import React from 'react';

const GravePhotoUpload = ({ onChange }) => {
    return (
        <Grid item xs={12}>
            <InputBase
                id="file-upload-damage-report"
                type="file"
                sx={{ display: 'none' }}
                onChange={onChange}
                inputProps={{
                    accept: 'image/*',
                    multiple: true,
                }}
            />
            <InputLabel htmlFor="file-upload-damage-report">
                <Box
                    sx={{
                        width: '100%',
                        height: 150,
                        mb: 1,
                        cursor: 'pointer',
                        padding: 2,
                        border: (theme) => `3px dashed ${theme.palette.primary.main}`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <AddAPhoto sx={{ mr: 1 }} />
                </Box>
            </InputLabel>
        </Grid>
    );
};

export default GravePhotoUpload;
