/**
 * Logged in user key name in local storage
 * @type {string}
 */
export const LOGGED_IN_USER_KEY = 'temetoLoggedInUser';

/**
 * Backend url for openapi
 * @type {string}
 */
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || `http://localhost:3001`;

/**
 * Default map for map component
 */
export const GRAVEYARD = 'graveyard';

/**
 * Default map for map component
 */
export const CEMETERY_BASE_MAP = 'basemap';

/**
 * Sentry DSN url
 * @type {string|string}
 */
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || 'nosentry';
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || '';

export const TILE_HOST = process.env.REACT_APP_TILE_HOST || 'https://temeto-teszt.envimap.hu';
