import { Style, Text } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { api } from '../../hooks/useApi';
import { useAppContext } from '../../providers/AppContext';
import { styles, textBase } from '../mapwrapper/MapWrapperStyles';

const ParcelsDataprovider = ({ extent, layerData, setSource, zoom }) => {
    const { selectedGraveyard } = useAppContext();

    const getParcels = useCallback(
        async (controller, params) =>
            api.geoms.getParcels(params, { signal: controller.signal }).then((res) => res.data),
        [api]
    );

    const addStyle = (sourceObject) =>
        sourceObject.map((e) => ({
            ...e,
            style: new Style({
                ...styles.parcel,
            }),
        }));

    useEffect(() => {
        if (zoom > 14.5 || !selectedGraveyard) {
            setSource(null);
            return;
        }

        const controller = new AbortController();
        getParcels(
            controller,
            layerData.ids.length > 0
                ? { graveyardId: selectedGraveyard.id, extent, ids: layerData.ids }
                : { graveyardId: selectedGraveyard.id, extent }
        )
            .then((graves) => {
                setSource(addStyle(graves));
            })
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    console.log(err);
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [getParcels, layerData, setSource, addStyle, selectedGraveyard, extent, zoom]);

    return null;
};

export default ParcelsDataprovider;
