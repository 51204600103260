import { Add, Close, Delete, Edit, KeyboardBackspace, Save, Undo } from '@mui/icons-material';
import { Button, CircularProgress, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Feature, toPolygon, useMap } from 'es-map-widget';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MAP_LAYER_NAMES } from '../../../constants/mapLayerNames';
import { api } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { useAppContext } from '../../../providers/AppContext';
import SectionSelect from '../SectionSelect';
import DrawButton from './DrawButton';
import { initialNewGraveValues } from './initialValues';
import { graveValidationSchema } from './validationSchema';

const EditGraveForm = ({ editGraveValues }) => {
    const map = useMap();
    const navigate = useNavigate();
    const { graveId } = useParams();
    const { setReloadGravesLayer } = useAppContext();
    const { selectedGraveyard } = useAppContext();
    const { notifySuccess, notifyError } = useNotify();
    const [isDrawing, setIsDrawing] = useState(false);
    const [graveGeom, setGraveGeom] = useState(editGraveValues?.geom || null);

    const addGrave = useCallback(
        (data) =>
            api.admin
                .addGrave(selectedGraveyard.id, data)
                .then(() => {
                    notifySuccess('Sír mentése sikeres');
                })
                .catch((err) => notifyError(err)),
        [selectedGraveyard]
    );

    const editGrave = useCallback(
        (data) =>
            api.admin
                .saveGrave(selectedGraveyard.id, graveId, data)
                .then(() => notifySuccess('Sír módosítása sikeres'))
                .catch((err) => notifyError(err)),
        [selectedGraveyard, graveId]
    );

    const setParcels = () => {
        map.setLayerData(MAP_LAYER_NAMES.PARCELS, {
            ids: selectedGraveyard.parcels.map((p) => p.id),
        });
        map.setLayerVisibility(MAP_LAYER_NAMES.PARCELS, true);
    };

    const onSubmit = async (values, { setSubmitting }) => {
        if (!graveGeom) {
            notifyError({ message: 'Nincs sírhely berajzolva' });
            return;
        }
        const newValues = {
            parcel: values.parcel || undefined,
            section: values.section || undefined,
            row: values.row,
            pos: values.pos,
            geom: graveGeom,
            description: values.description,
        };

        map.drawCancel();
        setIsDrawing(false);
        map.setLayerData(MAP_LAYER_NAMES.GEOMETRY, { geom: null });
        map.setLayerVisibility(MAP_LAYER_NAMES.GEOMETRY, false);
        if (graveId) {
            await editGrave(newValues);
            setSubmitting(false);
            setParcels();
            map.setLayerData(MAP_LAYER_NAMES.GRAVES, { ids: [] });
            navigate('/');
            return;
        }
        setSubmitting(false);
        await addGrave(newValues);
        setParcels();
        map.setLayerData(MAP_LAYER_NAMES.GRAVES, { ids: [] });
        navigate('/');
    };

    const handleCancel = () => {
        setParcels();
        map.drawCancel();
        map.setLayerData(MAP_LAYER_NAMES.GRAVES, { ids: [] });
        map.setLayerData(MAP_LAYER_NAMES.GEOMETRY, { geom: null });
        map.setLayerVisibility(MAP_LAYER_NAMES.GEOMETRY, false);
        navigate('/');
    };

    const saveGeom = useCallback(async () => {
        let feature;
        try {
            [feature] = map.drawSave();
        } catch (error) {
            console.log(error);
        }
        if (!feature) {
            map.drawPolygon();
            notifyError({ message: 'Nem sikerült a berajzolt terület mentése' });
            return;
        }
        const geom = {
            type: 'Polygon',
            coordinates: feature.getGeometry().getCoordinates(),
            crs: {
                type: 'name',
                properties: {
                    name: 'EPSG:23700',
                },
            },
        };
        setGraveGeom(geom);
        if (graveId) {
            map.setLayerData(MAP_LAYER_NAMES.GRAVES, {
                ids: [graveId],
            });
            map.setLayerVisibility(MAP_LAYER_NAMES.GRAVES, true);
            map.addPopoverToLayer(MAP_LAYER_NAMES.GRAVES);

            await editGrave({
                parcel: editGraveValues.parcel,
                row: editGraveValues.row,
                pos: editGraveValues.pos,
                section: editGraveValues.section,
                description: editGraveValues.description,
                geom: {
                    type: 'Polygon',
                    coordinates: feature.getGeometry().getCoordinates(),
                    crs: {
                        type: 'name',
                        properties: {
                            name: 'EPSG:23700',
                        },
                    },
                },
            });
            setReloadGravesLayer((p) => !p);
        } else {
            map.setLayerData(MAP_LAYER_NAMES.GEOMETRY, { geom: { geom } });
            map.setLayerVisibility(MAP_LAYER_NAMES.GEOMETRY, true);
        }
        setIsDrawing(false);
    }, [map, setGraveGeom, setIsDrawing, editGrave, editGraveValues, setReloadGravesLayer]);

    useEffect(() => {
        if (!selectedGraveyard) {
            map.setLayerData(MAP_LAYER_NAMES.GEOMETRY, { geom: null });
            map.setLayerVisibility(MAP_LAYER_NAMES.GEOMETRY, false);
            navigate('/');
        }
    }, [selectedGraveyard, navigate]);

    useEffect(() => {
        if (!editGraveValues) {
            return;
        }
        setGraveGeom(editGraveValues.geom);
    }, [setGraveGeom, editGraveValues]);

    return (
        <>
            <Formik
                initialValues={editGraveValues || initialNewGraveValues}
                onSubmit={onSubmit}
                validationSchema={graveValidationSchema}
                enableReinitialize
            >
                {({ submitForm, isSubmitting, touched, errors, values, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={2} sx={{ pb: 1 }}>
                            <Grid
                                item
                                container
                                xs={12}
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <IconButton onClick={handleCancel}>
                                    <KeyboardBackspace />
                                </IconButton>
                                <Typography variant="h6">
                                    {graveId ? 'Sírhely adatai' : 'Új sírhely'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Parcella"
                                    required
                                    fullWidth
                                    value={values.parcel || ''}
                                    onChange={(event) =>
                                        setFieldValue('parcel', event.target.value)
                                    }
                                    error={touched.parcel && !!errors.parcel}
                                    helperText={
                                        touched.parcel && errors.parcel ? `${errors.parcel}` : null
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Sor"
                                    required
                                    fullWidth
                                    value={values.row || ''}
                                    onChange={(event) => setFieldValue('row', event.target.value)}
                                    error={touched.row && !!errors.row}
                                    helperText={touched.row && errors.row ? `${errors.row}` : null}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Pozíció"
                                    required
                                    fullWidth
                                    value={values.pos || ''}
                                    onChange={(event) => setFieldValue('pos', event.target.value)}
                                    error={touched.pos && !!errors.pos}
                                    helperText={touched.pos && errors.pos ? `${errors.pos}` : null}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <SectionSelect
                                    label="Szekció"
                                    value={values.section}
                                    setValue={(event) =>
                                        setFieldValue('section', event.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    label="Sírhely leírása"
                                    fullWidth
                                    minRows={1}
                                    maxRows={4}
                                    multiline
                                    value={values.description || ''}
                                    onChange={(event) =>
                                        setFieldValue('description', event.target.value)
                                    }
                                    error={touched.description && !!errors.description}
                                    helperText={
                                        touched.description && errors.description
                                            ? `${errors.description}`
                                            : null
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                xs={12}
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Button
                                    onClick={handleCancel}
                                    disabled={isSubmitting}
                                    sx={{ mr: 1 }}
                                >
                                    Mégse
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={submitForm}
                                    disabled={!graveGeom || isDrawing}
                                >
                                    {isSubmitting ? <CircularProgress /> : 'Mentés'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <Typography variant="h6">Sírhely szerkesztése</Typography>
                </Grid>
                <Grid item xs={12}>
                    <DrawButton
                        disabled={!isDrawing}
                        onClick={() => {
                            setIsDrawing(false);
                            map.addPopoverToLayer(MAP_LAYER_NAMES.GRAVES);
                            map.drawCancel();
                        }}
                    >
                        <Close />
                    </DrawButton>
                    <DrawButton
                        disabled={!isDrawing}
                        onClick={() => {
                            map.drawPolygonUndo();
                        }}
                    >
                        <Undo />
                    </DrawButton>
                    <DrawButton disabled={!isDrawing} onClick={() => map.drawClear()}>
                        <Delete />
                    </DrawButton>
                    <DrawButton disabled={!isDrawing} onClick={saveGeom}>
                        <Save />
                    </DrawButton>
                    <DrawButton
                        disabled={isDrawing}
                        onClick={() => {
                            setIsDrawing(true);
                            if (graveGeom === null) {
                                map.drawPolygon();
                                return;
                            }
                            map.drawEdit(new Feature(toPolygon({ geom: graveGeom })));
                            map.removePopover();
                        }}
                    >
                        {graveGeom ? <Edit /> : <Add />}
                    </DrawButton>
                </Grid>
            </Grid>
        </>
    );
};

export default EditGraveForm;
