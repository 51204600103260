import { Style } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { api } from '../../hooks/useApi';
import { useAppContext } from '../../providers/AppContext';
import { styles } from '../mapwrapper/MapWrapperStyles';

const GravesDataprovider = ({ extent, layerData, setSource, zoom }) => {
    const { selectedGraveyard } = useAppContext();

    const getGraves = useCallback(
        async (controller, params) =>
            api.geoms.getGraves(params, { signal: controller.signal }).then((res) => res.data),
        [api]
    );

    const addStyle = (sourceObject) =>
        sourceObject.map((e) => {
            return {
                ...e,
                style: new Style({
                    ...styles.parcel,
                }),
            };
        });

    useEffect(() => {
        if (zoom <= 14.5 || !selectedGraveyard) {
            setSource(null);
            return;
        }

        const controller = new AbortController();
        getGraves(
            controller,
            layerData.ids.length > 0
                ? { graveyardId: selectedGraveyard.id, extent, ids: layerData.ids }
                : { graveyardId: selectedGraveyard.id, extent }
        )
            .then((graves) => {
                setSource(addStyle(graves));
            })
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    console.log(err);
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [getGraves, layerData, setSource, addStyle, selectedGraveyard, extent, zoom]);

    return null;
};

export default GravesDataprovider;
