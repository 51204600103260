import { Fill, FullScreen, getDefaultViewOptions, Stroke, Zoom } from 'es-map-widget';

const MAP_OPTIONS = {
    viewOptions: { ...getDefaultViewOptions() },
    selectOptions: {},
    selectLayerOptions: {},
    popupTemplate: {
        title: (f) => {
            return f.get('name') || '-';
        },
    },
    popupCondition: 'a',
    controls: [new FullScreen(), new Zoom()],
    drawOptions: {
        drawStyle: {
            fill: new Fill({ color: [0, 255, 0, 0.5] }),
            stroke: new Stroke({
                color: [0, 255, 0],
                width: 2,
            }),
        },
    },
};

// eslint-disable-next-line import/prefer-default-export
export { MAP_OPTIONS };
