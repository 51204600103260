/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import axios from 'axios';
export var ContentType;
(function (ContentType) {
    ContentType['Json'] = 'application/json';
    ContentType['FormData'] = 'multipart/form-data';
    ContentType['UrlEncoded'] = 'application/x-www-form-urlencoded';
    ContentType['Text'] = 'text/plain';
})(ContentType || (ContentType = {}));
export class HttpClient {
    instance;
    securityData = null;
    securityWorker;
    secure;
    format;
    constructor({ securityWorker, secure, format, ...axiosConfig } = {}) {
        this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '/api' });
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }
    setSecurityData = (data) => {
        this.securityData = data;
    };
    mergeRequestParams(params1, params2) {
        const method = params1.method || (params2 && params2.method);
        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method && this.instance.defaults.headers[method.toLowerCase()]) || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }
    stringifyFormItem(formItem) {
        if (typeof formItem === 'object' && formItem !== null) {
            return JSON.stringify(formItem);
        } else {
            return `${formItem}`;
        }
    }
    createFormData(input) {
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            const propertyContent = property instanceof Array ? property : [property];
            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
            }
            return formData;
        }, new FormData());
    }
    request = async ({ secure, path, type, query, format, body, ...params }) => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const responseFormat = format || this.format || undefined;
        if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
            body = this.createFormData(body);
        }
        if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
            body = JSON.stringify(body);
        }
        return this.instance.request({
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
            },
            params: query,
            responseType: responseFormat,
            data: body,
            url: path,
        });
    };
}
/**
 * @title Envimap Temető backend API
 * @version 1.0.0
 * @baseUrl /api
 */
export class Api extends HttpClient {
    users = {
        /**
         * No description
         *
         * @tags Users
         * @name LoginUser
         * @summary Get user data and token for user identified by username and password
         * @request POST:/users/login
         * @response `200` `UserWithToken`
         * @response `default` `APIError`
         */
        loginUser: (data, params = {}) =>
            this.request({
                path: `/users/login`,
                method: 'POST',
                body: data,
                ...params,
            }),
    };
    admin = {
        /**
 * No description
 *
 * @tags Graves
 * @name SearchGraves
 * @summary Search graves
 * @request POST:/admin/{graveyardId}/graves
 * @secure
 * @response `200` `{
    count?: number,
    items?: ((CemeteryGrave & {
    hasImage?: boolean,

}))[],

}` List of graves for the graveyard
 * @response `default` `APIError`
 */
        searchGraves: ({ graveyardId, ...query }, data, params = {}) =>
            this.request({
                path: `/admin/${graveyardId}/graves`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
        /**
 * No description
 *
 * @tags Graves
 * @name AddGrave
 * @summary Create a new grave
 * @request PUT:/admin/{graveyardId}/graves
 * @secure
 * @response `200` `{
    id?: number,

}` Created grave id
 * @response `default` `APIError`
 */
        addGrave: (graveyardId, data, params = {}) =>
            this.request({
                path: `/admin/${graveyardId}/graves`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
        /**
         * @description Retrieve the list of sections in the given graveyard
         *
         * @tags Graveyards
         * @name GetSections
         * @summary Get a sections list
         * @request GET:/admin/{graveyardId}/sections
         * @secure
         * @response `200` `(string | null)[]` One grave
         * @response `default` `APIError`
         */
        getSections: (graveyardId, params = {}) =>
            this.request({
                path: `/admin/${graveyardId}/sections`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),
        /**
         * @description Retrieve a grave
         *
         * @tags Graves
         * @name GetGrave
         * @summary Get a single grave
         * @request GET:/admin/{graveyardId}/grave/{graveId}
         * @secure
         * @response `200` `CemeteryGraveWithJoins` One grave
         * @response `default` `APIError`
         */
        getGrave: (graveyardId, graveId, params = {}) =>
            this.request({
                path: `/admin/${graveyardId}/grave/${graveId}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),
        /**
         * No description
         *
         * @tags Graves
         * @name SaveGrave
         * @summary Save a single cemetery grave
         * @request POST:/admin/{graveyardId}/grave/{graveId}
         * @secure
         * @response `200` `CemeteryGrave` Cemetery Grave saved successfully
         * @response `default` `APIError`
         */
        saveGrave: (graveyardId, graveId, data, params = {}) =>
            this.request({
                path: `/admin/${graveyardId}/grave/${graveId}`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
        /**
         * No description
         *
         * @tags Graves
         * @name DeleteGrave
         * @summary Delete a single cemetery grave
         * @request DELETE:/admin/{graveyardId}/grave/{graveId}
         * @secure
         * @response `200` `Status`
         * @response `default` `APIError`
         */
        deleteGrave: (graveyardId, graveId, params = {}) =>
            this.request({
                path: `/admin/${graveyardId}/grave/${graveId}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),
        /**
         * No description
         *
         * @tags Deceased
         * @name SaveDeceased
         * @summary Save a single deceased person data
         * @request POST:/admin/deceased/{deceasedId}
         * @secure
         * @response `200` `CemeteryDead` Cemetery Grave saved successfully
         * @response `default` `APIError`
         */
        saveDeceased: (deceasedId, data, params = {}) =>
            this.request({
                path: `/admin/deceased/${deceasedId}`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    };
    geoms = {
        /**
 * No description
 *
 * @tags Geoms
 * @name GetGraves
 * @summary Get graves for graveyard
 * @request GET:/geoms/{graveyardId}/graves
 * @secure
 * @response `200` `({
  \** The ID of the parcel *\
    id?: number,
  \** The name of the parcel *\
    name?: string,
    [key: string]: any,

})[]` List of graves for the graveyard
 * @response `default` `APIError`
 */
        getGraves: ({ graveyardId, ...query }, params = {}) =>
            this.request({
                path: `/geoms/${graveyardId}/graves`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),
        /**
 * No description
 *
 * @tags Geoms
 * @name GetParcels
 * @summary Get parcels for graveyard
 * @request GET:/geoms/{graveyardId}/parcels
 * @secure
 * @response `200` `({
  \** The ID of the parcel *\
    id?: number,
  \** The name of the parcel *\
    name?: string,
    [key: string]: any,

})[]` List of parcels for the graveyard
 * @response `default` `APIError`
 */
        getParcels: ({ graveyardId, ...query }, params = {}) =>
            this.request({
                path: `/geoms/${graveyardId}/parcels`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),
    };
    images = {
        /**
         * @description Retrieves the graveyards for a user that's logged in
         *
         * @tags Images
         * @name GetImages
         * @summary Get images for a grave or urn
         * @request GET:/images/{graveId}
         * @secure
         * @response `200` `(CemeteryPhoto)[]`
         * @response `default` `APIError`
         */
        getImages: ({ graveId, ...query }, params = {}) =>
            this.request({
                path: `/images/${graveId}`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
         * @description Retrieves the graveyards for a user that's logged in
         *
         * @tags Images
         * @name AddImages
         * @summary Add images for a grave or urn
         * @request PUT:/images/{graveId}
         * @secure
         * @response `200` `Status`
         * @response `default` `APIError`
         */
        addImages: ({ graveId, ...query }, data, params = {}) =>
            this.request({
                path: `/images/${graveId}`,
                method: 'PUT',
                query: query,
                body: data,
                secure: true,
                type: ContentType.FormData,
                ...params,
            }),
        /**
         * No description
         *
         * @tags Images
         * @name UpdateImages
         * @summary Update image date for a grave or urn
         * @request POST:/images/{graveId}/{imageId}
         * @secure
         * @response `200` `Status`
         * @response `default` `APIError`
         */
        updateImages: ({ imageId, graveId, ...query }, data, params = {}) =>
            this.request({
                path: `/images/${graveId}/${imageId}`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                ...params,
            }),
        /**
         * @description Retrieves the graveyards for a user that's logged in
         *
         * @tags Images
         * @name DeleteImages
         * @summary Delete images for a grave or urn
         * @request DELETE:/images/{graveId}/{imageId}
         * @secure
         * @response `200` `Status`
         * @response `default` `APIError`
         */
        deleteImages: ({ imageId, graveId, ...query }, params = {}) =>
            this.request({
                path: `/images/${graveId}/${imageId}`,
                method: 'DELETE',
                query: query,
                secure: true,
                ...params,
            }),
        /**
         * No description
         *
         * @tags Images
         * @name RotateImages
         * @summary Rotate image date for a grave or urn
         * @request POST:/images/{graveId}/{imageId}/rotate
         * @secure
         * @response `200` `Status`
         * @response `default` `APIError`
         */
        rotateImages: ({ imageId, graveId, ...query }, params = {}) =>
            this.request({
                path: `/images/${graveId}/${imageId}/rotate`,
                method: 'POST',
                query: query,
                secure: true,
                ...params,
            }),
    };
    graveyards = {
        /**
         * @description Retrieves the graveyards for a user that's logged in
         *
         * @tags Graveyards
         * @name GetGraveyards
         * @summary Get graveyards for the logged in user
         * @request GET:/graveyards
         * @secure
         * @response `200` `(GraveyardSearch)[]`
         * @response `default` `APIError`
         */
        getGraveyards: (params = {}) =>
            this.request({
                path: `/graveyards`,
                method: 'GET',
                secure: true,
                ...params,
            }),
    };
}
