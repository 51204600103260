import { useMap } from 'es-map-widget';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { GRAVEYARD } from '../config';
import { MAP_LAYER_NAMES } from '../constants/mapLayerNames';

const AppContext = createContext();
AppContext.displayName = 'AppContext';

function getGraveyardFromStorage() {
    try {
        return JSON.parse(localStorage.getItem(GRAVEYARD));
    } catch (err) {
        console.error(err);
        return null;
    }
}

export function AppContextProvider({ children }) {
    const map = useMap();
    const [graveyards, setGraveyards] = useState([]);
    const [selectedGraveyard, setSelectedGraveyardInternal] = useState(getGraveyardFromStorage);
    const [selectedItem, setSelectedItem] = useState(null);
    const [reloadGravesLayer, setReloadGravesLayer] = useState(false);

    const setGraveyardVisibility = useCallback(
        (id, layerName) => {
            try {
                if (!id) {
                    graveyards.forEach((e) => map.setLayerVisibility([`GRAVEYARD_${e.id}`], false));
                    return;
                }
                graveyards
                    .filter((e) => e.id !== id)
                    .forEach((e) => map.setLayerVisibility([`GRAVEYARD_${e.id}`], false));
                map.setLayerVisibility(layerName, true);
            } catch (e) {
                console.log(e);
            }
        },
        [map, graveyards]
    );

    const resetMap = useCallback(() => {
        try {
            map.setLayerData(MAP_LAYER_NAMES.PARCELS, { ids: [] });
            map.setLayerData(MAP_LAYER_NAMES.GRAVES, { ids: [] });
            map.zoomToGeomsExtent([{ geom: selectedGraveyard.geom }]);
            map.drawCancel();
        } catch (e) {
            console.log(e);
        }
    }, [map]);

    const zoomToSelectedGraveyard = useCallback(() => {
        try {
            map.zoomToGeomsExtent([{ geom: selectedGraveyard.geom }]);
        } catch (error) {
            console.log(error);
        }
    }, [selectedGraveyard, map]);

    const setSelectedGraveyard = useCallback(
        (newGraveyard) => {
            setSelectedGraveyardInternal(newGraveyard);
            if (newGraveyard === null) {
                localStorage.removeItem(GRAVEYARD);
            } else {
                localStorage.setItem(GRAVEYARD, JSON.stringify(newGraveyard));
            }
        },
        [setSelectedGraveyardInternal]
    );

    const contextValue = useMemo(
        () => ({
            resetMap,
            graveyards,
            setGraveyards,
            selectedGraveyard,
            setSelectedGraveyard,
            zoomToSelectedGraveyard,
            setGraveyardVisibility,
            selectedItem,
            setSelectedItem,
            reloadGravesLayer,
            setReloadGravesLayer,
        }),
        [
            resetMap,
            graveyards,
            setGraveyards,
            selectedGraveyard,
            setSelectedGraveyard,
            zoomToSelectedGraveyard,
            setGraveyardVisibility,
            selectedItem,
            setSelectedItem,
            reloadGravesLayer,
            setReloadGravesLayer,
        ]
    );

    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
}

export function useAppContext() {
    return useContext(AppContext);
}
